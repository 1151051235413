@tailwind base;
@tailwind components;
@tailwind utilities;

#marshmallow {
  position: relative;
}

body {
  display: flex;
  flex-direction: column;
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg,#fff,#ece1ed);
  padding: 50px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  max-width: 140rem;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
}

@media screen and (max-width: 800px) {
  body {
    padding: 10px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 991px) {
  .mobile-expand-container {
    max-height: 300vw;
  }
}


.mobile-expand-container.collapsed {
  margin-top: 0;
  max-height: 0;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.fade-in-1 {
  transition-delay: 0.1s;
}

.fade-in-2 {
  transition-delay: 0.2s;
}

.fade-in-3 {
  transition-delay: 0.3s;
}

.fade-in-4 {
  transition-delay: 0.4s;
}

.opened .fade-in {
  opacity: 1;
}
